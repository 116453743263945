import {compose} from "redux"

// Page component
import SignInPage from "../src/modules/authorization/pages/SignInPage"

// HOCs
import withAuthProtection from "../src/hoc/withAuthProtection"
import withSignIn, {WithSignInProps} from "../src/modules/authorization/hoc/withSignIn"
import withDeviceTypeRedirect from "../src/hoc/withDeviceTypeRedirect"

export type SignInPageProps = {
    className?: string
} & WithSignInProps

export default compose(
    withDeviceTypeRedirect,
    withAuthProtection,
    withSignIn,
)(SignInPage)
