import React from "react"
import {Dispatch} from "redux"
import {connect} from "react-redux"

// Actions
import * as a from "../store/actions"

// Models
import {AppState} from "../../../store/models"

// Selectors
import {isDemoStateSelector} from "../store/selectors"

export type WithDemoSignInProps = {
    demoSignIn(): void
    isDemo: boolean
}

export default function withSignIn(BaseComponent: React.ComponentType<any>) {
    return connect(
        (state: AppState) => isDemoStateSelector(state),
        (dispatch: Dispatch) => ({
            demoSignIn: () => dispatch(a.demoSignIn({username: "doctor@demo.com", password:"demo"})),
        }),
    )(BaseComponent)
}
