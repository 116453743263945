import {compose} from "redux"
import styled from "styled-components"

// Models
import {SignInCredentialsInterface} from "../../../../models"

// Component
import SignInForm from "./SignInForm"

// HOCs
import withUtils, {WithUtilsProps} from "../../../../../../hoc/withUtils"

export type SignInFormProps = {
    className?: string
    loading: boolean
    onSubmit: (credentials: SignInCredentialsInterface) => void
} & WithUtilsProps

const StyledSignInForm = styled(SignInForm)`
    & .forgot_container {
        margin-top: -17px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        
        & .ant-row {
            width: 100%;
        }
    }
    
    & .forgot_link {
        cursor: pointer;
        color: ${({theme}) => theme.color.blue}
    }
    
    & .live-demo-button {
        margin-top: 15px;
    }
    
    @media screen and (min-width: ${({theme}) => theme.breakpoints.lg}) {
        .live-demo-button {
            display: none;
        }
    }
`;

export default compose(
    withUtils,
)(StyledSignInForm)
