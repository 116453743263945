import React from "react"
import Button from "../Button"
import withDemo, {WithDemoSignInProps} from "../../modules/authorization/hoc/withDemoSignIn"

type Props = {

} & WithDemoSignInProps

const LiveDemoButton = (props: Props) => {
    const {demoSignIn} = props

    return (
        <Button block className="live-demo-button" onClick={demoSignIn}>
            Live demo
        </Button>
    )
}

export default withDemo(LiveDemoButton)