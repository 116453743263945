import styled from "styled-components"

// Component
import SignInPage from "./SignInPage"

const StyledSignInPage = styled(SignInPage)`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  
  .title {
    margin-bottom: 10px;
  }
` as any

export default StyledSignInPage