import React from "react"
import useTranslation from 'next-translate/useTranslation'

// Components
import SignInForm from "./components/SignInForm"
import AuthHeader from "../../components/AuthHeader"
import UnauthorizedPage from "../../../../components/UnauthorizedPage"

// Constants
import {Routes} from "../../../../constants"

// Models
import {SignInPageProps} from "../../../../../pages/sign-in"

export default function SignInPage(props: SignInPageProps): JSX.Element {
    const {signIn, signingIn, className = null} = props;
    const { t } = useTranslation();

    return (
        <UnauthorizedPage title={t('common:auth.sign_in')} className={className}>
            <AuthHeader
                title={t('common:auth.dont_have_an_account')}
                href={Routes.SIGN_UP}
                buttonText={t('common:auth.sign_up')}
            />
            <SignInForm
                onSubmit={signIn}
                loading={signingIn}
            />
        </UnauthorizedPage>
    )
}
