import {Dispatch} from "redux"
import {connect} from "react-redux"
import {NextComponentType} from "next"

// Actions
import * as a from "../store/actions"

// Models
import {SignInCredentialsInterface} from "../models"
import {AppState} from "../../../store/models"

// Selectors
import {signInStateSelector} from "../store/selectors"

export type WithSignInProps = {
    signIn(credentials: SignInCredentialsInterface): void
    signingIn: boolean
}

export default function withSignIn(BaseComponent: NextComponentType) {
    return connect(
        (state: AppState) => signInStateSelector(state),
        (dispatch: Dispatch) => ({
            signIn: (credentials: SignInCredentialsInterface) => dispatch(a.signIn(credentials)),
        }),
    )(BaseComponent)
}
