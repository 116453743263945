import React from "react"
import {Form} from "antd"
import {Field, Formik, FormikValues} from "formik"
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

// Components
import {Input} from "../../../../../../components/createFormItem"
import Button from "../../../../../../components/Button"
import AuthFormPlate from "../../../../components/AuthFormPlate"
import LiveDemoButton from "../../../../../../components/LiveDemoButton"

// Models
import {SignInFormProps} from "./index"

// Constants
import {Routes} from "../../../../../../constants"

const SignInForm = (props: SignInFormProps) => {
    const {
        className,
        loading,
        onSubmit,
        utils: {
            validateEmail,
            validatePassword,
        },
    } = props;
    const { t } = useTranslation();

    function handleFormSubmit(values: FormikValues) {
        const {email: username, password} = values;
        onSubmit({username, password});
    }

    return (
        <AuthFormPlate title={t('common:auth.sign_in')} className={className}>
            <Formik onSubmit={handleFormSubmit} initialValues={{email: "", password: ""}}>
                {({handleSubmit, isValid}) => (
                    <Form onFinish={handleSubmit} className={className}>
                        <Field
                            name="email"
                            type="email"
                            placeholder={t('common:email')}
                            validate={validateEmail}
                            component={Input}
                        />
                        <div className="forgot_container">
                            <Link href={Routes.RESET_PASSWORD} as={Routes.RESET_PASSWORD}>
                                <a className="forgot_link" tabIndex={-1}>
                                    {t('common:auth.forgot_password')}?
                                </a>
                            </Link>
                            <Field
                                name="password"
                                type="password"
                                placeholder={t('common:auth.password')}
                                validate={validatePassword}
                                component={Input}
                            />
                        </div>
                        <Button
                            block
                            type="primary"
                            htmlType="submit"
                            loading={isValid && loading}
                            disabled={isValid === false}
                        >
                            {t('common:auth.sign_in')}
                        </Button>
                        <LiveDemoButton/>
                    </Form>
                )}
            </Formik>
        </AuthFormPlate>
    );
}

export default SignInForm